<template>
    <v-container fluid tag="section">

        <div class="text-end">
            
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-title>
                        <v-row class="py-2">
                            <v-col cols="12" md="9">
                                <v-btn outlined class="font-weight-bold me-2">
                                    {{ $t('Filter') }} <v-icon right>mdi-filter</v-icon>
                                </v-btn>
                                <v-btn outlined class="font-weight-bold me-2">
                                    {{ $t('Export') }} <v-icon right>mdi-file-table</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="3" class="text-md-end">
                                <v-text-field hide-details flat filled outlined solo dense single-line
                                    append-icon="mdi-magnify" :label="$t('Search')" v-model="search"
                                    @keyup.enter="onSearch"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="px-0 py-0">
                        <v-data-table :items="items" :headers="headers" :options.sync="options"
                            :server-items-length="totalItems" :loading="loading" class="elevation-1">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{ row.item.id }}</td>
                                    <td>
                                        <router-link v-for="user in row.item.company?.users" :to="`/users/edit/${user.id}`" class="pr-2">
                                            {{ user.email }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link :to="`/plans/edit/${row.item.plan_id}`">
                                            {{ row.item.plan?.name }}
                                        </router-link>
                                    </td>
                                    <td>{{ row.item.balance }}</td>
                                    <td>{{ row.item.period_start_date | utcToLocal }}</td>
                                    <td>{{ row.item.period_end_date | utcToLocal }}</td>
                                    <td>{{ row.item.created_at | utcToLocal }}</td>
                                    <td>
                                        <v-chip small :color="statusColor(row.item.status)" class="font-weight-bold">
                                            {{ $t(row.item.status.name) }}
                                        </v-chip>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import subscriptionService from '@/services/subscriptionService'

export default {
    data() {
        return {
            items: [],
            totalItems: 0,
            loading: true,
            options: { sortBy: ['id'], sortDesc: ['true'] },
            search: null,
            headers: [
                { text: '#', value: 'id' },
                { text: 'User', value: 'user_email' },
                { text: 'Plan', value: 'plan_name' },
                { text: 'Balance', value: 'balance' },
                { text: 'Start', value: 'period_end_date' },
                { text: 'End', value: 'period_end_date' },
                { text: 'Created', value: 'created_at' },
                { text: 'Status', value: 'status_id' },
            ],
            dialog: false,
            dialogItems: []
        }
    },
    watch: {
        options: {
            handler(curr, prev) {
                const watchProps = ['page', 'itemsPerPage', 'sortBy', 'sortDesc']
                for (let i = 0; i < watchProps.length; i++) {
                    const key = watchProps[i]
                    if (prev[key] !== curr[key]) {
                        return this.getSubscriptions()
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        async getSubscriptions() {
            this.loading = true
            try {
                const result = await subscriptionService.paginate({
                    ...this.options,
                    search: this.search,
                    include_users: true,
                    include_plan: true,
                })
                this.items = result.data.data
                this.totalItems = result.data.total
                this.options.page = result.data.page

            } catch (error) {
                this.$dialog.message.error(error.message)
            } finally {
                this.loading = false
            }
        },
        onSearch() {
            this.getSubscriptions()
        },
        statusColor(status) {
            const colors = {
                trialing: 'success',
                active: 'success',
                canceled: '',
                incomplete: 'warning',
                incomplete_expired: 'error',
                past_due: 'error',
                unpaid: 'warning',
                paused: '',
            }
            return colors[status.alias]
        },
    },
}
</script>
